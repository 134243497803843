import { withDependencies } from '@wix/thunderbolt-ioc'
import { DomReadySymbol, IAppWillMountHandler, ILogger, LoggerSymbol } from '@wix/thunderbolt-symbols'

export const createDomReadyPromise = () =>
	new Promise((resolve) => {
		if (document.readyState === 'complete' || document.readyState === 'interactive') {
			resolve()
		} else {
			document.addEventListener('DOMContentLoaded', resolve)
		}
	})

export const WaitForDomReady = withDependencies<IAppWillMountHandler>(
	[DomReadySymbol, LoggerSymbol],
	(domReady, logger) => ({
		appWillMount: () => domReady.then(() => reportDomReady(logger)).then(verifySiteContainer),
	})
)

function reportDomReady(logger: ILogger) {
	logger.phaseMark('domReady')
}

function verifySiteContainer(): void {
	const ssrReturnedBody = typeof window.clientSideRender !== 'undefined'
	if (!ssrReturnedBody) {
		window.clientSideRender = true
		window.santaRenderingError = window.santaRenderingError || {
			errorInfo: 'body failed to render',
		}

		const pagesCss = window.document.createElement('pages-css')
		pagesCss.setAttribute('id', 'pages-css')
		window.document.body.appendChild(pagesCss)

		const siteContainer = window.document.createElement('DIV')
		siteContainer.setAttribute('id', 'site-container')
		window.document.body.appendChild(siteContainer)
	}
}
